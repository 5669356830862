import React, { useState, useEffect } from 'react';
import { SellerNav } from './DealerNav';
import { useParams } from "react-router-dom";
import api from '../api';

export const BidHistory = () => {
  const { Vehicle_Id } = useParams();
  const [bids, setBids] = useState([]); // All bids
  const [topBids, setTopBids] = useState([]);
  const [newBidAmount, setNewBidAmount] = useState(''); // New bid input field
  const [editBidAmount, setEditBidAmount] = useState({}); // Editable bid values
  const dealerId = sessionStorage.getItem("user_id");
  const [responseMessage, setResponseMessage] = useState("");

  // Fetch bid history data from API
  const fetchBidHistory = async () => {
    try {
      const response = await api.get(`/bidhistory/${Vehicle_Id}`);
      const data = response.data;
      setTopBids(data.TopBids);
    } catch (error) {
      console.error('Error fetching bid history:', error);
    }
  };

  useEffect(() => {
    if (Vehicle_Id) {
      fetchBidHistory();
    }
  }, [Vehicle_Id]);

  // Handle save action for editing the bid
  const handleSaveBid = async (bidId) => {
    try {
      const response = await api.post('/dealer/dealerbidprice', {
        Bid_Id: bidId,
        Dealer_Id: dealerId,
        Vehicle_Id: Vehicle_Id,
        Bid_Amount: editBidAmount[bidId] // Send updated bid amount
      });
      setResponseMessage('Bid updated successfully!');
      setTimeout(() => setResponseMessage(''), 3000); // Clear message after 3 seconds
      fetchBidHistory(); // Refresh bid history after saving
    } catch (error) {
      console.error('Error saving bid:', error);
    }
  };

  const handleBidPriceChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\d.]/g, "");
    const parts = value.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const formattedValue = `$${parts.join(".")}`;
    setNewBidAmount(formattedValue);
  };

  // Handle adding a new bid
  const handleAddNewBid = async () => {
    const numericBidPrice = parseFloat(newBidAmount.replace(/[$,]/g, ''));
    try {
      const response = await api.post("/dealer/dealerbidprice", {
        Bid_Amount: numericBidPrice,
        Dealer_Id: dealerId,
        Vehicle_Id: Vehicle_Id,
      });
      setResponseMessage(response.data.message || "Bid price saved successfully");
      setTimeout(() => setResponseMessage(''), 3000); // Clear message after 3 seconds
      fetchBidHistory();
    } catch (error) {
      console.error("Error saving bid price:", error);
    }
  };

  // Find if there is a bid by the current dealer
  const yourNegotiation = topBids.find(
    (negotiation) => negotiation.Dealer_Id === Number(dealerId)
  );

  return (
    <section className="car-details">
      <SellerNav />
      <div className="container">
        {responseMessage && (
          <div className="alert alert-success alert-dismissible fade show" role="alert">
            <p className="text-success">{responseMessage}</p>
            <button type="button" className="close" onClick={() => setResponseMessage("")} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}

        <div className="bidding-historyinfo mt-5">
          <h3 className="main-heading text-center">Bid History</h3>
          <div className="bid-historyinner mt-4">
            <div className="table-panel">
              <table className="table table-bordered mid-table">
                <thead>
                  <tr>
                    <th scope="col">Bid Amount</th>
                    <th scope="col">Bid DATE-TIME</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {topBids.length > 0 ? (
                    topBids.map((bid) => (
                      <tr key={bid.Bid_Id}>
                        <td>
                          {bid.Dealer_Id === Number(dealerId) ? (
                            <input
                              type="text"
                              value={editBidAmount[bid.Bid_Id] || bid.Bid_Amount}
                              onChange={(e) =>
                                setEditBidAmount({
                                  ...editBidAmount,
                                  [bid.Bid_Id]: e.target.value
                                })
                              }
                            />
                          ) : (
                            `$${parseFloat(bid.Bid_Amount).toLocaleString()}`
                          )}
                        </td>
                        <td>{new Date(bid.Bid_Date).toLocaleString('en-US', { timeZone: 'America/New_York' })}</td> {/* EDT Timezone */}
                        <td>
                          {bid.Dealer_Id === Number(dealerId) ? (
                            <button className="btn btn-primary" onClick={() => handleSaveBid(bid.Bid_Id)}>
                              Save
                            </button>
                          ) : (
                            <span>Cannot edit</span>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center">
                        No Top Bids Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* Add New Bid Section - Only show if no existing bid by the dealer */}
              {!yourNegotiation && (
                <div className="add-new-bid mt-5">
                  <h4>Add Your Bid</h4>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={newBidAmount}
                      onChange={handleBidPriceChange}
                      placeholder="Enter your bid amount"
                    />
                  </div>
                  <button className="btn btn-primary" onClick={handleAddNewBid}>
                    Save Bid
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
